import { queryRun } from "@fscrypto/domain";
import { OnChangeFn, SortingState, Table } from "@fscrypto/table";
import { Tooltip } from "@fscrypto/ui";
import clsx from "clsx";
import { RotateCcwSquare } from "lucide-react";
import { memo, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { getDateTimeDurationFromDates, getObjectSizeAsString } from "~/utils/helpers";
import { generateTableColumns } from "./generate-table-columns";

interface TableProps {
  results: queryRun.QueryRunResult;
  dashboardView?: boolean;
  previewView?: boolean;
  isTransposed?: boolean;
  toggleTransposed?: () => void;
  onSortingChange?: OnChangeFn<SortingState>;
  sorting?: SortingState;
  tableId: string;
  filter?: string;
  onFilterChange?: (value: string) => void;
  pageIndex?: number;
  onPageIndexChange?: (newPage: number) => void;
}

const ResultsTableComponent = ({
  results,
  isTransposed = false,
  toggleTransposed,
  tableId,
  dashboardView,
  previewView,
  sorting = [],
  onSortingChange,
  filter,
  onFilterChange,
  pageIndex,
  onPageIndexChange,
}: TableProps) => {
  const columns = useMemo(() => generateTableColumns(results, isTransposed), [results, isTransposed]);
  const startedAt = results.startedAt ? new Date(results.startedAt) : new Date();
  const endedAt = results.endedAt ? new Date(results.endedAt) : new Date();

  const duration = results.startedAt && results.endedAt ? getDateTimeDurationFromDates(startedAt, endedAt) : "";

  return (
    <ViewContainer dashboardView={dashboardView}>
      <ErrorBoundary FallbackComponent={ErrorResults}>
        <Table
          tableId={tableId}
          columns={columns}
          data={results.csvData}
          paginate
          initialPageSize={20}
          headerInfo={<TableInformation results={results} handleTranspose={toggleTransposed} duration={duration} />}
          dashboardView={dashboardView}
          previewView={previewView}
          transpose={isTransposed}
          onSortingChange={onSortingChange}
          sorting={sorting}
          filter={filter}
          onFilterChange={onFilterChange}
          onPageIndexChange={onPageIndexChange}
          pageIndex={pageIndex}
        />
      </ErrorBoundary>
    </ViewContainer>
  );
};

export const ResultsTable = memo(ResultsTableComponent);

const ErrorResults = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      Something went wrong while rendering your results
    </div>
  );
};

interface TableInformationProps {
  results: queryRun.QueryRunResult;
  handleTranspose?: () => void;
  duration: string;
}

const TableInformation = ({ results, handleTranspose, duration }: TableInformationProps) => {
  return (
    <div className="bg-background dark:text-gray-30 flex justify-between p-3 text-sm text-gray-50">
      <div className="flex items-center">
        <div className="items center mr-1 flex items-center">
          <span className="text-green-60 mr-1">Success</span>
          <div>{results.csvData.length} rows |</div>
        </div>
        <div>
          {getObjectSizeAsString(results.csvData)} in {duration}
        </div>
      </div>
      <div className="pr-0.5">
        <Tooltip content="Rotate" side="top">
          <span>
            <RotateCcwSquare
              className="hover:bg-gray-15 text-content mr-2 inline h-5 w-5 cursor-pointer rounded text-sm"
              onClick={() => {
                handleTranspose?.();
              }}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

const ViewContainer = ({ children, dashboardView }: { children: React.ReactNode; dashboardView?: boolean }) => {
  return (
    <div
      className={clsx("w-full max-w-full overflow-hidden", {
        "h-[calc(100%_-_10px)]": !dashboardView,
        "h-[calc(100%_-_36px)]": dashboardView,
      })}
    >
      {children}
    </div>
  );
};
