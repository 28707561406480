import { QueryRunResult } from "@fscrypto/domain/query-run";
import { DateTime } from "luxon";
import { memo } from "react";
import { useElapsedTime } from "use-elapsed-time";
import { z } from "zod";

const dateSchema = z.coerce.date().default(() => new Date());

export const ElapsedTimeComponent = ({
  queryRunResult,
  queryId,
}: {
  queryId: string;
  queryRunResult?: QueryRunResult;
}) => {
  const startedAt = dateSchema.parse(queryRunResult?.startedAt);
  const startDateTime = DateTime.fromJSDate(startedAt);
  const diff = DateTime.now().diff(startDateTime).milliseconds;
  const { elapsedTime } = useElapsedTime({ isPlaying: true, startAt: diff / 1000 });
  return <div className="text-content/50 w-full text-center text-xs">Elapsed time... {elapsedTime.toFixed(1)}</div>;
};

export const ElapsedTime = memo(ElapsedTimeComponent, (prevProps, nextProps) => {
  return prevProps.queryId === nextProps.queryId;
});
