import { Button, Card, Text } from "@fscrypto/ui";
import clsx from "clsx";
import { useQueryRun } from "../../state/query-run";
import { Link } from "@remix-run/react";
import { DateTime } from "luxon";
import { plan, queryRun } from "@fscrypto/domain";
import { useElapsedTime } from "use-elapsed-time";
import { useCurrentUser } from "~/features/current-user";
import { memo, useState } from "react";
import { ExecutionType, QueryRunResult } from "@fscrypto/domain/query-run";
import { ElapsedTime } from "./elapsed-time";
import { CheckIcon, RotateCw } from "lucide-react";

const ActiveSteps = ({ queryId, executionType }: { queryId: string; executionType?: ExecutionType }) => {
  const {
    queryRunResult,
    cancelQuery,
    isCanceling,
    isQueueing,
    isRunning,
    isDownloading,
    isExecuting,
    isCanceled,
    isFetchingInitial,
  } = useQueryRun(queryId, executionType);

  const currentStep = currentStepFromState({
    isExecuting,
    isCanceling,
    isQueueing,
    isRunning,
    isDownloading,
    isFetchingInitial,
  });
  return (
    <div
      data-testid="query-run-loading"
      className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden"
    >
      <QueryRunProgress
        currentStep={currentStep}
        isCanceled={isCanceled}
        isCanceling={isCanceling}
        cancelQuery={cancelQuery}
        queryRunResult={queryRunResult}
        queryId={queryId}
      />
      {queryRunResult && <ElapsedTimeUpsellPanel status={queryRunResult.status} />}
    </div>
  );
};

export default ActiveSteps;

const currentStepFromState = ({
  isExecuting,
  isCanceling,
  isQueueing,
  isRunning,
  isDownloading,
  isFetchingInitial,
}: {
  isExecuting?: boolean;
  isCanceling?: boolean;
  isQueueing?: boolean;
  isRunning?: boolean;
  isDownloading?: boolean;
  isFetchingInitial?: boolean;
}) => {
  if (isCanceling) {
    return 0;
  }
  if (isExecuting || isQueueing || isFetchingInitial) {
    return 1;
  }
  if (isRunning) {
    return 2;
  }
  if (isDownloading) {
    return 3;
  }
  return 3;
};

const createStepStatuses = (currentStep: number) => {
  return [
    { name: "Queue", status: currentStep === 1 ? "current" : "complete" },
    { name: "Execute", status: currentStep === 1 ? "upcoming" : currentStep === 2 ? "current" : "complete" },
    { name: "Download", status: currentStep <= 2 ? "upcoming" : currentStep === 3 ? "current" : "complete" },
  ];
};

const QueryRunProgress = ({
  currentStep,
  isCanceled,
  isCanceling,
  cancelQuery,
  queryRunResult,
  queryId,
}: {
  currentStep: number;
  isCanceled?: boolean;
  isCanceling?: boolean;
  cancelQuery?: () => void;
  queryRunResult?: QueryRunResult;
  queryId: string;
}) => {
  const steps = createStepStatuses(currentStep);
  return (
    <div aria-label="Progress">
      <div className="relative mb-4 h-4">
        {currentStep === 2 && <ElapsedTime queryRunResult={queryRunResult} queryId={queryId} />}
      </div>
      <ol
        className={clsx("flex w-full items-center", {
          "opacity-40": currentStep === 0,
        })}
      >
        {steps.map((step, stepIdx) => (
          <li key={step.name} className={clsx(stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "", "relative")}>
            {step.status === "complete" ? (
              <div>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="bg-primary h-0.5 w-full" />
                </div>
                <div className="bg-primary relative flex h-8 w-8 items-center justify-center rounded-full ">
                  <CheckIcon className="dark:text-gray-80 h-5 w-5 text-white" />
                </div>
                <div className="text-primary absolute -left-1 mt-2 text-xs">{step.name}</div>
              </div>
            ) : step.status === "current" ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-50" />
                </div>
                <div
                  className="dark:bg-gray-80 border-primary relative flex h-8 w-8 items-center justify-center rounded-full border-2 bg-white"
                  aria-current="step"
                >
                  <RotateCw className="text-primary size-5 animate-spin" />
                </div>
                <div className="dark:text-gray-30 absolute -left-[6px] mt-2 text-xs">{step.name}</div>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-50" />
                </div>
                <div className="dark:bg-gray-90 group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white dark:border-gray-50">
                  <span className="h-2.5 w-2.5 rounded-full  bg-gray-300 dark:bg-gray-50" aria-hidden="true" />
                  <span className="sr-only">{step.name}</span>
                </div>
                <div className="absolute -left-3 mt-2 text-xs text-gray-300 dark:text-gray-50">{step.name}</div>
              </>
            )}
          </li>
        ))}
      </ol>
      <div className="mb-4 mt-6 flex h-[30px] w-full justify-center">
        {(currentStep === 2 || currentStep === 0) && (
          <CancelButton isCanceling={isCanceling} isCanceled={isCanceled} onCancel={cancelQuery} />
        )}
      </div>
    </div>
  );
};

interface CancelButtonProps {
  isCanceling?: boolean;
  isCanceled?: boolean;
  onCancel?: () => void;
}

export const CancelButton = ({ isCanceling, onCancel }: CancelButtonProps) => {
  return (
    <div className="mt-4">
      <Button variant="secondary" size="sm" onClick={onCancel} disabled={isCanceling}>
        {isCanceling ? "Cancelling" : "Cancel"}
      </Button>
    </div>
  );
};

const UpsellPanelComponent = ({ status }: { status?: queryRun.QueryRun["status"] }) => {
  const { userPlan } = useCurrentUser();
  // Initialize startedAt state
  const [startedAt] = useState<string>(new Date().toISOString());
  const isFreePlan = userPlan?.plan ? plan.isFree(userPlan.plan.code) : true;
  const secs = ((DateTime.now().diff(DateTime.fromISO(startedAt)) as any)?.values?.milliseconds ?? 1) / 1000;
  const { elapsedTime } = useElapsedTime({ isPlaying: true, startAt: secs });
  if (!isFreePlan || status === "finished") return null;
  if (elapsedTime < 15) return null;
  return (
    <Card variant="info" className="mt-4 select-none p-4 px-6" data-testid="queryrun-upsell">
      <Text className="font-semibold" as="p">
        Want results faster?
      </Text>
      <Text size="sm">
        <Link to="/settings/plan" className="text-primary/80" target="_blank">
          Upgrade
        </Link>{" "}
        to boost your querying speed 🚀
      </Text>
    </Card>
  );
};

const ElapsedTimeUpsellPanel = memo(UpsellPanelComponent);
