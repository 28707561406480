import { ExecutionType } from "@fscrypto/domain/query-run";
import { Actor } from "./actor";
import { useActorFromSystem } from "~/state/system";

export const useQueryRun = (queryId: string, executionType?: ExecutionType) => {
  const [state, ref] = useActorFromSystem<Actor>(`query-${queryId}-run-${executionType || "REALTIME"}`);

  if (!state || !ref)
    return {
      isReady: false,
    } as const;
  return {
    isReady: true,
    status: state.context.queryRunResult?.status,
    executeQueryRun: () => ref.send({ type: "QUERY_RUN.EXECUTE" }),
    // queryRun: state.context.queryRun,
    queryRunResult: state.context.queryRunResult,
    cancelQuery: () => ref.send({ type: "QUERY_RUN.CANCEL_QUERY" }),
    //todo: finish canceling
    isExecuting: state.matches("executing") || state.matches("executingEphemeral"),
    isQueueing: state.matches("polling") && state.context.queryRunResult?.status === "queued",
    isRunning: state.matches("polling") && state.context.queryRunResult?.status === "running",
    isCanceling: state.matches("canceling"),
    isCanceled: state.matches("canceled"),
    isDownloading: state.matches("fetchingResults"),
    isIdle: state.matches("idle"),
    canRun: state.matches("idle") || state.matches("error") || state.matches("canceled") || state.matches("complete"),
    isError: state.matches("error"),
    isComplete: state.matches("complete"),
    errorMessage: state.context.errorMessage,
    isFetchingInitial: state.matches("fetchingInitial"),
    statement: state.context.statement,
    isInMotion:
      state.matches("executing") ||
      state.matches("executingEphemeral") ||
      state.matches("polling") ||
      state.matches("fetchingResults"),
  };
};
