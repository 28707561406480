import { memo } from "react";
import ActiveSteps from "./active-steps";
import { useQueryRun } from "../../state/query-run";
import { Card, LogoIcon, Text } from "@fscrypto/ui";
import { QueryResultsTable } from "../query-results-table/query-results-table";
import { ExecutionType } from "@fscrypto/domain/query-run";
import { Link } from "@remix-run/react";
import { plan } from "@fscrypto/domain";
import { useCurrentUser } from "~/features/current-user";
import { AlertTriangle } from "lucide-react";

const QueryRunPanelComponent = ({ queryId, executionType }: { queryId: string; executionType?: ExecutionType }) => {
  const { queryRunResult, isComplete, isCanceled, isIdle, isError, errorMessage } = useQueryRun(
    queryId,
    executionType ?? "REALTIME",
  );

  switch (true) {
    case queryRunResult?.status === "failed" || isError:
      return <FailedRun message={queryRunResult?.errorMessage || errorMessage} />;
    case isCanceled:
      return <CancelledRun />;
    case isComplete:
      return <QueryResultsTable tableId={`query-${queryId}-results-${executionType}`} />;

    case !isComplete && !isIdle:
      return <ActiveSteps queryId={queryId} executionType={executionType} />;

    case isIdle || !queryRunResult:
      return <EmptyQueryRun />;

    default:
      // This case should never be reached due to the conditions above covering all scenarios
      return null;
  }
};

export const QueryRunPanel = memo(QueryRunPanelComponent);

export const CancelledRun = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <AlertTriangle className="size-10 text-warning" />
      <Text size="xs">Cancelled Run</Text>
    </div>
  );
};

export const EmptyQueryRun = ({ isDashboard }: { isDashboard?: boolean }) => {
  return (
    <div className="bg-surface flex h-full w-full flex-col items-center justify-center gap-y-2 py-6">
      <LogoIcon className="size-10 opacity-40" />
      <Text color="muted" size="sm">
        {isDashboard ? "No Data to display" : "Run a query to Download Data"}
      </Text>
    </div>
  );
};

export const FailedRun = ({ message }: { message?: string | null }) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <AlertTriangle className="text-warning h-10 w-10" />
      <Text color="muted" size="sm">
        {message ?? "Error Running Query"}
      </Text>
      <UpsellPanel message={message ?? ""} />
    </div>
  );
};

const UpsellPanel = ({ message }: { message: string }) => {
  const { userPlan } = useCurrentUser();
  const isFreePlan = userPlan?.plan ? plan.isFree(userPlan.plan.code) : true;
  const isTimeout = message.includes("Statement reached its statement or warehouse timeout");
  if (!isFreePlan || !isTimeout) return null;
  return (
    <Card variant="info" className="mt-6 p-4" data-testid="api-upsell">
      <Text size="sm">
        Uh-oh, timeout. Your query has been running for our max of 10 minutes.{" "}
        <Link to="/pricing" className="text-primary/80">
          Upgrade
        </Link>
        {` `} for 2x higher limits and faster results.
      </Text>
    </Card>
  );
};
