import { SortingState, Updater } from "@fscrypto/table";
import { Actor } from "./actor";
import { useActorFromSystem } from "~/state/system";

export const useQueryResultsTable = (tableId: `query-${string}-results-${string}`) => {
  const [state, ref] = useActorFromSystem<Actor>(tableId);

  if (!state || !ref) return {} as const;
  return {
    results: state.context.results,
    isTransposed: state.context.transposed,
    toggleTransposed: () => ref.send({ type: "QUERY_RESULTS_TABLE.TOGGLE_TRANSPOSED" }),
    sorting: state.context.sorting,
    filter: state.context.filter,
    setSorting: (sorting: Updater<SortingState>) =>
      ref.send({ type: "QUERY_RESULTS_TABLE.SET_SORTING", payload: { sorting } }),
    setFilter: (filter: string) => ref.send({ type: "QUERY_RESULTS_TABLE.SET_FILTER", payload: { filter } }),
    setPageIndex: (page: number) => ref.send({ type: "QUERY_RESULTS_TABLE.SET_PAGE", payload: { page } }),
    pageIndex: state.context.pageIndex,
  };
};
