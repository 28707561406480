import { memo } from "react";
import { useQueryResultsTable } from "../../state/query-results-table";
import { ResultsTable } from "./results-table";

const QueryResultsTableComponent = ({ tableId }: { tableId: `query-${string}-results-${string}` }) => {
  const { results, sorting, setSorting, filter, setFilter, setPageIndex, pageIndex, isTransposed, toggleTransposed } =
    useQueryResultsTable(tableId);
  if (!results) return null;
  return (
    <div data-testid="query-run-success" className="h-full max-h-full w-full overflow-hidden">
      <ResultsTable
        results={results}
        sorting={sorting}
        isTransposed={isTransposed}
        toggleTransposed={toggleTransposed}
        onSortingChange={setSorting}
        filter={filter}
        onFilterChange={setFilter}
        tableId={tableId}
        pageIndex={pageIndex}
        onPageIndexChange={setPageIndex}
      />
    </div>
  );
};

export const QueryResultsTable = memo(QueryResultsTableComponent);
